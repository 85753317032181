import Landingpage from "./components/Landingpage";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Preloader from "./components/preloder";

function App() {
  return (
    <>
      <Landingpage />
    </>
  );
}

export default App;




